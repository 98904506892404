.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f4f4f4;
  color: #333;

  &__content {
    max-width: 600px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    &--title {
      font-size: 2.5em;
      margin-bottom: 0.5em;
      color: #2c3e50;
    }

    &--text {
      font-size: 1.2em;
      line-height: 1.5;
      color: #34495e;
    }
  }
}
